function IconDeparting(props) {
	return (
		<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M2.52011 18.715H21.5201V20.715H2.52011V18.715ZM22.0901 9.35503C21.8801 8.55503 21.0501 8.07503 20.2501 8.29503L14.9401 9.71503L8.04011 3.28503L6.11011 3.79503L10.2501 10.965L5.28011 12.295L3.31011 10.755L1.86011 11.145L4.45011 15.635L21.0201 11.205C21.8301 10.975 22.3001 10.155 22.0901 9.35503Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export default IconDeparting;
